<template>
    <div>
        <ListingComponent ref="UsersList"
                          class="bg-white"
                          :showFilterButton="true"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
        <b-modal v-model="isDeleteModal"
                 :no-close-on-backdrop="true"
                 centered title="Delete modal"
                 @cancel="closeDeleteModal"
                 @ok="removeItem">
            <form>
                <h3 class="text-black-50">Do you really want to delete</h3>
            </form>
        </b-modal>
        <b-modal v-model="isBlockModal"
                 :no-close-on-backdrop="true"
                 centered title="Block modal"
                 @cancel="closeBlockModal"
                 @ok="blockUser">
            <form>
                <h3 class="text-black-50">Do you want to block this user</h3>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import ListingComponent from '@/components/listing/ListingComponent'

    export default {
        name: "UsersListPage",
        data () {
            return {
                id: null,
                isDeleteModal: false,
                isBlockModal: false,
                columnConfig: [
                  {
                    columnCode: 'action',
                    columnName: 'Action',
                    value: (item) => {
                      return (
                          <b-dropdown variant="link" class="listing-action">
                          <b-dropdown-item onClick={() => this.showBlockUserModal(item)}>Block</b-dropdown-item>
                          <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
                      </b-dropdown>
                    )
                    }
                  },
                  {
                    columnCode: 'FIRST_NAME',
                    columnName: 'First Name',
                    link: '/history/useraudios',
                    align: 'left',
                    value: item => {
                      return item.firstName;
                    }
                  },
                  {
                    columnCode: 'LAST_NAME',
                    columnName: 'Last Name',
                    link: '/history/useraudios',
                    align: 'left',
                    value: item => {
                      return item.lastName;
                    }
                  },
                  {
                    columnCode: 'PHONE',
                    columnName: 'Phone',
                    align: 'left',
                    value: item => {
                      return item.phoneNumber;
                    }
                  },
                  {
                    columnCode: 'USERNAME',
                    columnName: 'User Name',
                    align: 'left',
                    value: item => {
                      return item.userName;
                    }
                  },
                  {
                    columnCode: 'TYPE',
                    columnName: 'Type',
                    align: 'left',
                    value: item => {
                      return item.botBlocked && item.botBlocked === true ?'Inactive':'Active';
                    }
                  }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('telegramUsers/list', payload)
            },
          addNewButton () {
            return (
                <router-link class="btn btn-success mr-3" to="#"><i class="fas fa-plus-circle"></i></router-link>


          )
          },
          closeDeleteModal() {
            this.id = null;
            this.$nextTick(() => {
              this.isDeleteModal = false;
            });
          },
          closeBlockModal() {
            this.id = null;
            this.$nextTick(() => {
              this.isBlockModal = false;
            });
          },
          showDeleteModal(item) {
            if (!item.id) {
              this.$toastr.error('Users not found');
            }
            this.id = item.id;
            this.isDeleteModal = true;
          },
          showBlockUserModal(item) {
            if (!item.id) {
              this.$toastr.error('Users not found');
            }
            this.id = item.id;
            this.isBlockModal = true;
          },
          removeItem() {
            if (!this.id) {
              return;
            }
            this.axios.delete('telegramUsers/item/', {params: {id: this.id}})
                .then((result) => {
                  this.$refs.UsersList.doRequest();
                  if (result.data && result.data.status === 'success') {
                    this.$toastr.success('User successfully deleted');
                  }
                  this.isDeleteModal = false;
                });
          },
          blockUser() {
            if (!this.id) {
              return;
            }
            this.axios.delete('telegramUsers/block-user/', {params: {id: this.id}})
                .then((result) => {
                  this.$refs.UsersList.doRequest();
                  if (result.data && result.data.status === 'success') {
                    this.$toastr.success('User successfully deleted');
                  }
                  this.isBlockModal = false;
                });
          }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>